import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { isBefore } from 'date-fns';
import { IDropdownSettings, ListItem } from 'ng-multiselect-dropdown/multiselect.model';
import { map, take } from 'rxjs/operators';
import { OrganizationService } from '../../services/organization.service';
import { EditMissionResult } from './edit-mission-result';
import { EditableMission } from './editable-mission';
import { FirebaseListItem } from './firebase-list-item';

@Component({
  selector: 'mk-mission-editor',
  templateUrl: 'mission-editor.component.html',
  styleUrls: ['mission-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionEditorComponent implements OnInit {
  @Input() dialogTitle: string | undefined;
  @Input() mission: EditableMission | undefined;
  @Input() appConfigArchivesEnabled: boolean | undefined;

  missionTitle: string | null = null;
  startDate = new Date();
  endDate = new Date();
  location: string | null = null;
  description: string | null = null;
  archivesEnabled = false;
  allUsers: ListItem[] = [];
  allGroups: ListItem[] = [];
  selectedGroups: ListItem[] = [];
  selectedUsers: ListItem[] = [];
  allOrganizations: ListItem[] = [];
  selectedOrganizations: ListItem[] = [];
  userHasMultipleOrganization = true;

  multiselectSettings: IDropdownSettings = {
    singleSelection: false,
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };

  singleSettings: IDropdownSettings = {
    singleSelection: true,
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };

  constructor(
    protected dialogRef: NbDialogRef<MissionEditorComponent>,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    if (this.mission) {
      this.missionTitle = this.mission.title;
      this.startDate = this.mission.startDate;
      this.endDate = this.mission.endDate;
      this.location = this.mission.location;
      this.description = this.mission.description;
      this.archivesEnabled = this.mission.archivesEnabled;
      this.selectedUsers = this.mission.selectedUsers;
      this.allUsers = this.mission.allUsers;
      this.allGroups = this.mission.allGroups;
      this.selectedGroups = this.mission.selectedGroups;
      this.allOrganizations = this.mission.allOrganizations;
      this.selectedOrganizations = this.mission.selectedOrganizations;
      this.userHasMultipleOrganization = this.allOrganizations.length > 1;
    }
  }

  checkDates(startDate: Date) {
    if (isBefore(this.endDate, startDate)) {
      this.endDate = startDate;
    }
  }

  async dismiss() {
    this.dialogRef.close();
  }

  async submit() {
    let organization: ListItem | null;

    if (this.userHasMultipleOrganization) {
      organization = this.selectedOrganizations ? this.selectedOrganizations[0] : null;
    } else {
      organization = this.allOrganizations ? this.allOrganizations[0] : null;
    }

    const result: EditMissionResult = {
      title: this.missionTitle,
      startDate: this.startDate,
      endDate: this.endDate,
      location: this.location,
      description: this.description,
      archivesEnabled: this.archivesEnabled,
      users: this.selectedUsers.map(toFirebaseListItem),
      groups: this.selectedGroups.map(toFirebaseListItem),
      organization: organization ? toFirebaseListItem(organization) : null,
      selectedMobileStreams: this.mission!.selectedMobileStreams,
    };

    if (await this.isValid(this.mission!, result)) {
      this.dialogRef.close(result);
    }
  }

  private isValid(editable: EditableMission, edited: EditMissionResult): Promise<boolean> {
    return this.organizationService.currentOrganization
      .pipe(
        take(1),
        map((organization) => {
          if (
            edited.archivesEnabled !== editable.archivesEnabled &&
            edited.archivesEnabled !== organization.archivesEnabled
          ) {
            return confirm(
              `Archives are ${
                organization.archivesEnabled ? 'enabled' : 'disabled'
              } by default by your organization. Are you sure you want to ${
                edited.archivesEnabled ? 'enable' : 'disabled'
              } them?`
            );
          } else {
            return true;
          }
        })
      )
      .toPromise();
  }
}

function toFirebaseListItem(item: ListItem): FirebaseListItem {
  return {
    item_id: item.id as string,
    item_text: item.text as string,
  };
}
